.flipCard {
    background-color: transparent;
    width: 34rem;
    height: 52.5rem;
    align-self: center;
    perspective: 1000px; /* 3D effect */
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipCard:hover .flipCardInner {
    transform: rotateY(180deg);
  }
  
  .flipCardFront,
  .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border: 0;
    border-radius: 2.6rem;
  }
  
  .flipCardFront {
    background-color: #000;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: #000;
  }
  
  .flipCardBack {
    background-color: #000;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    transform: rotateY(180deg);
  }

  .closeButton {
    position: relative;
    top: 2rem;
    left: -2rem;
    background-color: #066543;
    border: 0;
    border-radius: 50%;
    font-family: sans-serif;
    font-size: 3.5rem;
    cursor: pointer;
    color: #fff;
    width: 4rem;
    height: 4rem;
    text-align: center;
    z-index: 1001;
  }