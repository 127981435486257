@use './colors';
@use './grid';
@use './fonts';

.formComponents {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-evenly;
}

.inputBox, h2 {
  padding: 1rem;
}

.inputBox {
  display: flex;
  flex-direction: column;
}

.offlineBox {
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: colors.$primary-gold;
  color: colors.$primary-red;
  font-size: large;
}

@media (min-width: 1300px) {
  .inputBox {
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
}

.loginErrorPlaceholder, .loginErrorContainer {
  transition: all 0.5s;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.loginErrorPlaceholder {
  background-color: rgb(255, 255, 255, 0);
}
.loginErrorContainer {
  background-color: colors.$primary-gold;
  color: colors.$primary-red;
  font-size: large;
  height: 3.5rem;
  display: flex;
  align-items: center;
  margin: 0;
}
.loginErrorContainer > span {
  display: contents;
}

.inputLabel {
  display: flex;
  align-items: center;
  margin: 0;
}

.icon {
  font-size: 2rem;
  color: #fff;
  margin: 0.5rem;
}

.authButton {
  font-size: 1.5rem;
  margin: 1.4rem;
  padding: 2.3rem 3rem;
}

@media (min-width: 1300px) {
  .authButton {
    font-size: 1.5rem;
    margin: 1rem;
    padding: 0.5rem 3rem;
  }
}

.authButton .icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  color: #fff;
}

h1 {
  margin: 2.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-family: fonts.$primary-fonts;
  color: #fff;
}

h2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: #fff;
  margin-right: 1rem;
}

h2 .icon {
  font-size: 2.5rem;
  color: colors.$primary-white;
  padding-right: 1rem;
}