.App {
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.App.loaded {
  opacity: 1;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

/* theme */
.appmanager {
  background-image: url('./images/t.png');
  background-size: cover;
  background-position: center;
}
.admin {
  background-image: url('./images/t1.png');
  background-size: cover;
  background-position: center;
}

.independentstudent {
  background-image: url('./images/t0.png');
  background-size: cover;
  background-position: center;
}

.trustedadult {
  background-image: url('./images/t3.png');
  background-size: cover;
  background-position: center;
}

.dependentstudent {
  background-image: url('./images/t2.png');
  background-size: cover;
  background-position: center;
}

.signUp {
  background-image: url('./images/t4.png');
  background-size: cover;
  background-position: center;
}
