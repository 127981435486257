@use './colors';
@use './fonts';

.byOneGrid {
  display: grid;
  grid-template-columns: 100%;
  max-width: 1080px;
  align-self: center;
  gap: 2rem;
}

.twoByTwoGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  max-width: 1080px;
  align-self: center;
}

.messageSentCount {
  font-family: fonts.$heading-fonts;
  text-align: center;
  color: colors.$primary-gold;  
}

.messageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.messageGrid * {
  align-content: center;
}

.messageGridHeadings {
  display: contents;
}

.messageGridHeadings h2 {
  font-family: fonts.$heading-fonts;
  text-align: left;
}

.messageRow {
  display: contents;
  margin: 2rem;
  padding: 1rem;
  border: 1px solid colors.$primary-grey;
  border-radius: 1rem;
  background-color: colors.$primary-glass;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  transition: all 0.7s;
}

.messageRow > h2 {
  font-family: fonts.$description-fonts;
}

.messageRow > div {
  padding: 1rem 0;
}