.cardItem {
  cursor: pointer;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

.selected {
  border: 2px solid #066543;
}

.unselected {
  border: 2px solid transparent;
}

.cardSelectThumb {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}