@use './colors';
@use './generic';

.bottomMenuContainer {
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
  color: colors.$primary-grey;
  transition: generic.$color-transition;
  padding: 0 2rem 0 2rem;
}

.link:hover {
  color: colors.$primary-gold;
  transition: generic.$color-transition;
}

.icon {
  font-size: 2.5rem;
}

.link:icon {
  color: colors.$primary-grey;
}