@use './generic';
@use './form';

.subHeading {
  color: #faa543;
  padding: 1rem;
  font-size: 2rem;
}

ol {
  text-align: left;
}

li {
  margin: 0 2rem 1rem 1rem;
}

label, select, input[type="checkbox"], input[type="button"] {
  margin: 1rem;
  text-align: center;  
}

.nextButton {
  margin: 0 0 2rem 0;  
}

button:hover, nextButton:hover {
  background-color: #30a13b;
}

button > svg {
  vertical-align: bottom;
}

.formComponents {
  /* screen -> 100%/2 and input box -> 20rem/2 */
  margin-left: calc(50% - 10rem);
}

.inputWrapper {
  position: relative;
  display: inline-block;
}

.unit {
  position: absolute;
  right: 5px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  color: #333; /* Adjust to match your design */
  pointer-events: none; /* Ensure clicks pass through */
}

.blurb {
  color: #fff;
  font-family: 'Open Sans Light', sans-serif;
  padding-left: 1rem;
}

.blurb ol {
  text-align: left;
}

.acceptToggle {
  margin: auto;
  padding: 2rem 1rem 3rem 2rem;
  text-align: center;
  display: inline-block;
}

.acceptToggle > label, .acceptToggle > label > span {
  width: 100%;
  padding-left: 1rem;
}

.toggleLabelText {
  padding-left: 1rem;
  vertical-align: super;
}

.studentInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.studentInfoContainer input {
  border: 1px #066543 solid;
}

.studentInfoContainer input[type="text"],
.studentInfoContainer input[type="number"],
textarea {
  /* trick labels into showing above with % */
  width: calc(0% + 20rem);
}

.studentInfoContainer input[type="checkbox"] {
  width: 3rem;
}

.studentToggleContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

label, .errorLabel {
  font-family: 'Intrigora Med', sans-serif;
  color: #fff;
  display: block;
  font-weight: normal;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.2em;
}

.errorLabel {
  color: #faa543;
  text-transform: capitalize;
}
