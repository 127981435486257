@use './generic';
@use './colors';
@use './blur';
@use './fonts';

.iconMessage {
  display: flex;
  align-items: center;
  cursor: default;
  transition: generic.$bgcolor-transition;
  background-color: colors.$primary-glass;
  backdrop-filter: blur.$primary-glass-blur;
  border-radius: blur.$primary-glass-menu-radius;
}

.iconMessage:hover {
  background-color: colors.$primary-green;
}

.icon {
  font-size: 3rem;
  margin: 1rem;
  color: colors.$primary-gold;
}

/* icon message decorations by type attribute */
.default {
  border: 0;
}
.offer {
  border: 2px solid colors.$primary-gold;
}
