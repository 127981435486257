/* shuriken loader */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  z-index: 1999;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.loading-screen.hidden {
  opacity: 0;
  pointer-events: none;
}

.loader {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000; /* Student ID is 1000, FullScreenWrapper is 500 */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
}

.shuriken {
  width: 5rem;
  height: 5rem;
  display: grid;
  color:#faa543;
  -webkit-mask: radial-gradient(circle 5px,#0000 90%,#000);
  animation:sh 1.5s infinite linear;
}
.shuriken:before,
.shuriken:after {
  content: "";
  grid-area: 1/1;
  background: 
    radial-gradient(farthest-side at bottom left, currentColor 94%,#0000) top left, 
    radial-gradient(farthest-side at top right  , currentColor 94%,#0000) bottom right;
  background-size: 63% 50%;
  background-repeat: no-repeat;
  -webkit-mask: 
    radial-gradient(65% 110% at bottom left, #0000 94%,#000) top left, 
    radial-gradient(65% 110% at top   right, #0000 94%,#000) bottom right;
  -webkit-mask-size: 62% 50%;
  -webkit-mask-repeat: no-repeat;
}
.shuriken:after {
  transform: rotate(90deg);
}

@keyframes sh {
  0%, 100% {
    transform: rotate(0deg);
  }
  2% {
    transform: rotate(40deg);
  }
  5% {
    transform: rotate(150deg);
  }
  20% {
    transform: rotate(250deg);
  }
  50% {
    transform: rotate(360deg);
  }
  70% {
    transform: rotate(250deg);
  }
  85% {
    transform: rotate(150deg);
  }
  95% {
    transform: rotate(40deg);
  }
  98% {
    transform: rotate(0deg);
  }
}
