@use './grid';
@use './form';
@use './colors';
@use './fonts';
@use './shadow';

.inboxContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 94vw;
}

.messageHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
}

.sentDate {  
  font-family: fonts.$heading-fonts;
  margin-left: auto;
  color: colors.$primary-gold;
  padding-right: 2rem;
}

p {  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content {
  text-align: left;
  padding-left: 1rem;
  padding-right: 2rem;
}

.markAsReadButton {
  font-size: 1.2rem;
  padding: 1rem;
}

.slidyDiv {
  /*transition: background-position 1s;*/
  background-color: colors.$primary-glass;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /*width: 94vw;*/
  text-align: center;
  margin: 0 2rem 1rem 1rem;
  border-color: colors.$primary-white;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
}

@keyframes collapseHeight {
  0% {
    display: contents;
  }
  100% {
    display: none;
  }
}

.slidyDivSelected {
  /*background-size: 200% 100%;
  background-image: linear-gradient(to left, colors.$primary-glass 50%, colors.$primary-green 50%);
  background-position: 0 0;*/
  animation: collapseHeight 0.5s forwards;
}