@use './colors';

/* for both logo and menu items */
.shadow {
  -webkit-filter: drop-shadow(7px 7px 10px #000);
  filter: drop-shadow(7px 7px 10px #000);
}

.shadowGreen {
  color: colors.$primary-green;
  -webkit-filter: drop-shadow(7px 7px 10px #000);
  filter: drop-shadow(7px 7px 10px #000);
}

.shadowGold {
  color: colors.$primary-gold;
  -webkit-filter: drop-shadow(7px 7px 10px #000);
  filter: drop-shadow(7px 7px 10px #000);
}