@use './generic';
@use './form';
@use './bottomMenu';
@use './colors';
@use './blur';
@use './fonts';
@use './grid';
@use './map';

.inputLabel {
  font-family: fonts.$description-fonts;
  color: colors.$primary-white;
  display: block;
  font-weight: normal;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.2em;
  min-height: 1.5rem;
  margin: 1rem 1rem 1rem;
  padding-left: 2rem;
}

.icon {
  font-size: 2rem;
  color: colors.$primary-white;
  margin: 0.5rem;
}

.buttonContainer {
  height: calc(2.3rem + 2.3rem + 1.5rem);
  display: flex;
  align-items: stretch;
  padding-left: 1rem;
}

.button {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.closeButton {
  font-size: 1.5rem;
  margin: auto;
}

.cancelButton {
  font-size: 1.5rem;
  margin-left: 1rem;
  background-color: colors.$primary-gold;
}

h2 .icon {
  font-size: 2.5rem;
  color: colors.$primary-white;
  padding-right: 1rem;
}

.errorPlaceholder, .errorContainer {
  transition: all 0.5s;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0 0 15px 15px;
}
.errorPlaceholder {
  background-color: colors.$primary-invis;
}
.errorContainer {
  background-color: colors.$primary-gold;
}
.error {  
  color: colors.$primary-red;
  font-size: xx-large;
}

.infoContainerTop, .infoContainerBottom {
  transition: all 0.5s;
  height: 6.3rem;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;  
}
.infoContainerTop {
  background-color: colors.$primary-white;
  border-radius: 15px 15px 0 0;
}
.infoContainerBottom {
  background-color: colors.$primary-green;
  border-radius: 0 0 15px 15px;
}
.infoMargin {  
  padding: 2rem;
}
.infoTop {  
  color: colors.$primary-green;
  font-size: xx-large;
}
.infoBottom {  
  color: colors.$primary-gold;
  font-size: xx-large;
}

.cardIdStatusGif {
  color: colors.$primary-white;
}

.info {
  font-family: fonts.$emphasis-fonts;
  color: colors.$primary-white;
  font-size: 1.5rem;
  text-align: left;
  padding-left: 2rem;
}

.info .icon {
  font-size: 1.5rem;
  color: colors.$primary-white;
  padding-right: 1rem;
}

.info .button {
  color: colors.$primary-white;
  background-color: colors.$primary-green;
  padding: 0.6rem 1.6rem;
  border-radius: 4px;
  display: flex;
  text-decoration: none;
}

.messageLength {
  color: colors.$primary-gold;
  width: 30rem;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chartContainer, .qrContainer {
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin: auto;
}

.chart, .chartHeading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  
}

.chartHeading {
  background-color: colors.$primary-glass-medium;
  border-radius: 1rem 1rem 0 0;
}

.chartHeading h2 {
  font-family: 'Open Sans Light', monospace;
}

.chart {
  background-color: colors.$primary-glass-light;
  border-radius: 0 0 1rem 1rem;
}

.chartPadding {
  margin: 1rem;
  padding: 1.5rem 1.5rem 0.6rem 1.5rem;
}

.chartDark {
  background: colors.$chart-dark;
}

.chartLight {
  background: colors.$chart-light;
}