@use './colors';

.react-tabs {
  width: -webkit-fill-available;
  width: -moz-available;
}

.react-tabs__tab {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 0 !important;
  font-size: 1.5rem;
  color: colors.$primary-grey;
}

.react-tabs__tab-list {
  border-bottom: 1px solid colors.$primary-white !important;
}

.react-tabs__tab--selected, .react-tabs__tab li {
  color: colors.$primary-green !important;
}

.react-tabs__tab-panel {
  box-sizing: border-box;
}