@use './colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

form {
  justify-content: space-evenly;
  background-color: colors.$primary-glass;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 0;
  font-family: 'Edo SZ', sans-serif;
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  width: -moz-available;
}

label {
  margin: auto;
}

.formFullWidthComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  gap: 1rem;
}

input, textarea {
  border-radius: 0.3rem;
  width: 30rem;
  padding: 0.5rem;
  margin: 0.5rem;
  font-family: 'Open Sans Light', sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  box-sizing: border-box;
}

textarea {
  width: 30rem;
  height: 10rem;
}

button {
  background-color: colors.$primary-green;
  color: colors.$primary-white;
  border: none;
  padding: 0.5rem 3rem;
  cursor: pointer;
  font-size: large;
  font-family: 'Edo SZ', sans-serif;
  border-radius: 5px;
  align-self: flex-end; 
}

.wide-button {
  width: 12rem;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.bounce-in {
  animation: bounce-in-right 4s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  50% {
    opacity: 1;
    transform: translateX(-30px);
  }
  70% { transform: translateX(10px); }
  90% { transform: translateX(0); }
  100% { opacity: 1; }
}

.hidden {
  display: none;
  opacity: 0;
}

span {
  border-radius: 0.3rem;
  padding: 0;
  margin: 0;
}

.status {
  text-align: center;
  display: inline-block;
}

.zoomControls button {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  font-size: x-large;
  text-align: left;
  padding: 0.2rem 1rem 0 1.6rem;
  margin: 0.5rem;
}

.toggleLabelText {
  padding-left: 1rem;
  vertical-align: super;
}