$primary-gold: #faa543;
$primary-green: #066543;
$primary-red: #b51517;

$primary-black: #000;
$primary-grey: #ccc;
$primary-white: #fff;

$primary-glass-light: rgba(255, 255, 255, 0.5);
$primary-glass-medium: rgba(6, 101, 67, 0.75);
$primary-glass: rgba(255, 255, 255, 0.1);
$primary-invis: rgba(255, 255, 255, 0);

$chart-dark: rgb(66, 66, 66);
$chart-light: #fff;