@use './colors';
@use './fonts';
@use './shadow';

$primary-transition: all 0.3s;
$color-transition: color 0.3s;
$bgcolor-transition: background-color 0.3s;

#root {
  width: -webkit-fill-available;
  width: -moz-available;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

body {
  background-color: colors.$primary-black;
  margin: 0;
  font-family: fonts.$primary-fonts;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

h1 {
  font-family: fonts.$heading-fonts;
  text-align: center;
  color: colors.$primary-white;
  @extend .shadow;
}

h2 {
  font-family: fonts.$heading-fonts;
  margin: 0;
  color: colors.$primary-white;
  @extend .shadow;
}

span {
  margin: 1rem;
}

p {
  margin: 0 1rem;
  color: colors.$primary-white;
  font-family: fonts.$description-fonts;
  letter-spacing: 0.05rem;
  line-height: 1.4;
  padding: 1rem;
  font-size: large;
  padding-bottom: 1rem;
  @extend .shadow;
}

.header img {
  padding: 1rem;
}

main {
  display: flex;
  flex-direction: column;
}

.footer {
  position: fixed;
  bottom: 0;
  width: -moz-available;
  width: 100%;
  padding: 1rem;
  color: colors.$primary-grey;
  background-color: colors.$primary-black;
}