@use './colors';
@use './blur';
@use './generic';

.profileItems {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-grow: 1;
  overflow-y: auto;
}

.menuItem, .wideItem {
  padding: blur.$primary-glass-menu-padding;
  transition: generic.$primary-transition;
  border: 0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$primary-glass;
  backdrop-filter: blur.$primary-glass-blur;
  border-radius: blur.$primary-glass-menu-radius;
  margin: blur.$primary-glass-menu-margin;
}

.menuItem { /* Fixed width on bigger screens */
  width: 26vw;
}

@media (max-width: 700px) { /* Full width on smaller screens */
  .menuItem {
    width: 100%;
  }
}

.menuItem h2 {
  font-family: 'Edo SZ', sans-serif;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.menuItem:hover {
  background-color: colors.$primary-green;
}

.menuGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  padding: 2rem;
}

.icon {
  font-size: 5rem;
  margin-bottom: 1rem;
  color: colors.$primary-gold;
}