@use './styles/colors';
@use './styles/generic';
@use './styles/menu';
@use './styles/form';
@use './styles/tab';
@use './styles/grid';
@use './styles/shadow';

@font-face {
  font-family: 'Open Sans Light';
  src: url('./styles/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Intrigora Med';
  src: url('./styles/fonts/Intrigora-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Stiff Brush JK';
  src: url('./styles/fonts/stiffbrushjk.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Edo SZ';
  src: url('./styles/fonts/edosz.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}